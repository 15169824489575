import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonIcon, ButtonGroupContainer, StyledInput } from './styles';

class ButtonGroup extends React.Component {
    render() {
        var { Buttons, ButtonWidth, className, ContainerWidth, NormalWhiteSpace, NotTouching, OwnRows } = this.props;

        return (
            <ButtonGroupContainer className={className} ContainerWidth={ContainerWidth} FitContent={ButtonWidth ? 1 : 0} OwnRows={OwnRows}>
                {
                    Buttons.map(({ BackgroundColor, BackgroundColorHover, BackgroundColorSelected, Border, BorderHover, Color, ColorHover, ColorSelected, Disabled, FontFamily, FontSize, Icon, IconPosition, IconSize, IsInput, InputProps, OnClick, Selected, Size, TextAlign, Title }, index) => {                        
                        return (
                            <ButtonContainer
                                key={index}
                                BackgroundColor={BackgroundColor}
                                BackgroundColorHover={BackgroundColorHover}
                                BackgroundColorSelected={BackgroundColorSelected}
                                Border={Border}
                                BorderHover={BorderHover}
                                ButtonWidth={ButtonWidth}
                                Color={Color}
                                ColorHover={ColorHover}
                                ColorSelected={ColorSelected}
                                Disabled={Disabled}
                                FontFamily={FontFamily}
                                FontSize={FontSize}
                                IconPosition={IconPosition}
                                IsButton={(OnClick || !!IsInput) ? 1 : 0}
                                NormalWhiteSpace={NormalWhiteSpace}
                                NotTouching={NotTouching}
                                onClick={Disabled ? () => null : OnClick}
                                OwnRows={OwnRows}
                                Selected={Selected}
                                Size={Size}
                                TextAlign={TextAlign}
                            >
                                {
                                    Icon && (IconPosition === 'left' || IconPosition === 'top') ?
                                    <ButtonIcon IconSize={IconSize} src={Icon} alt="Button icon" />
                                :
                                    null
                                }
                                {Title}
                                
                                {!!IsInput && <StyledInput {...InputProps} />}
                                {
                                    Icon && IconPosition === 'right' ?
                                    <ButtonIcon IconSize={IconSize} src={Icon} alt="Button icon" />
                                :
                                    null
                                }
                            </ButtonContainer>
                        );
                    })
                }
            </ButtonGroupContainer>
        );
    }
}

ButtonGroup.propTypes = {
    Buttons: PropTypes.array.isRequired,
    ButtonWidth: PropTypes.string,
    ContainerWidth: PropTypes.string,
    NormalWhiteSpace: PropTypes.bool,
    NotTouching: PropTypes.bool,
    OwnRows: PropTypes.bool
}

export default ButtonGroup;