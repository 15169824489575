import {
    TRYING_DOWNLOAD_PAYOUT_CSV,
    TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS,
    TRYING_DOWNLOAD_PAYOUT_CSV_ERROR,

    TRYING_GENERATE_PAYOUT,
    TRYING_GENERATE_PAYOUT_SUCCESS,
    TRYING_GENERATE_PAYOUT_ERROR,

    TRYING_GET_UNPAID_PAYOUTS,
    TRYING_GET_UNPAID_PAYOUTS_SUCCESS,
    TRYING_GET_UNPAID_PAYOUTS_ERROR,

    TRYING_UPDATE_PAYOUT_STATUS,
    TRYING_UPDATE_PAYOUT_STATUS_SUCCESS,
    TRYING_UPDATE_PAYOUT_STATUS_ERROR
} from '../Types';

const INITIAL_STATE = {
    UnpaidPayouts: [],

    TryingDownloadPayoutCSV: false,
    TryingDownloadPayoutCSVError: null,
    TryingGeneratePayout: false,
    TryingGeneratePayoutError: null,
    TryingGetUnpaidPayouts: false,
    TryingGetUnpaidPayoutsError: null,
    TryingUpdatePayoutStatus: false,
    TryingUpdatePayoutStatusError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DOWNLOAD_PAYOUT_CSV:
            return { ...state, TryingDownloadPayoutCSV: true, TryingDownloadPayoutCSVError: null };
        case TRYING_DOWNLOAD_PAYOUT_CSV_ERROR:
            return { ...state, TryingDownloadPayoutCSV: false, TryingDownloadPayoutCSVError: action.payload };
        case TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS:
            return { ...state, TryingDownloadPayoutCSV: false, TryingDownloadPayoutCSVError: null };

        case TRYING_GENERATE_PAYOUT:
            return { ...state, TryingGeneratePayout: true, TryingGeneratePayoutError: null };
        case TRYING_GENERATE_PAYOUT_ERROR:
            return { ...state, TryingGeneratePayout: false, TryingGeneratePayoutError: action.payload };
        case TRYING_GENERATE_PAYOUT_SUCCESS:
            return { ...state, TryingGeneratePayout: false, TryingGeneratePayoutError: null };

        case TRYING_GET_UNPAID_PAYOUTS:
            return { ...state, TryingGetUnpaidPayouts: true, TryingGetUnpaidPayoutsError: null };
        case TRYING_GET_UNPAID_PAYOUTS_ERROR:
            return { ...state, TryingGetUnpaidPayouts: false, TryingGetUnpaidPayoutsError: action.payload };
        case TRYING_GET_UNPAID_PAYOUTS_SUCCESS:
            return { ...state, UnpaidPayouts: action.payload, TryingGetUnpaidPayouts: false, TryingGetUnpaidPayoutsError: null };

        case TRYING_UPDATE_PAYOUT_STATUS:
            return { ...state, TryingUpdatePayoutStatus: true, TryingUpdatePayoutStatusError: null };
        case TRYING_UPDATE_PAYOUT_STATUS_ERROR:
            return { ...state, TryingUpdatePayoutStatus: false, TryingUpdatePayoutStatusError: action.payload };
        case TRYING_UPDATE_PAYOUT_STATUS_SUCCESS:
            // eslint-disable-next-line
            var PayoutIndex = action.payload;

            var NewUnpaidPayouts = [ ...state.UnpaidPayouts.slice(0, PayoutIndex), ...state.UnpaidPayouts.slice(PayoutIndex + 1) ];

            return { ...state, UnpaidPayouts: NewUnpaidPayouts, TryingUpdatePayoutStatus: false, TryingUpdatePayoutStatusError: null };

        default:
            return state;
    }
};
