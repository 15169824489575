import styled from 'styled-components';

export const Container = styled.div`
    background-color: var(--light-gray);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    max-width: 80%;
    padding: 40px 60px;
    position: relative;
    width: 767px;

    @media (max-width: 767px) {
        border-radius: 0px;
        margin: 0px;
        max-width: none;
        width: 100%;
    }
`;

export const ForgotPasswordImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    & img {
        height: 270px;
        width: 270px;

        @media (max-width: 767px) {
            height: 160px;
            width: 160px;
        }
    }
`;

export const LeftArrowContainer = styled.div`
    left: 20px;
    position: absolute;
    top: 40px;

    & img {
        height: 30px;
        width: 32.56px;

        @media (max-width: 767px) {
            height: 20px;
            width: 21.7px;

            left: 20px;
            top: 20px;
        }
    }

    :hover {
        cursor: pointer;
    }
`;