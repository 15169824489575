import styled from 'styled-components';
import { device } from '../../Config/device';

import { Link } from 'react-router-dom';

export const BreadcrumbLink = styled(Link)`
    color: var(--blue-abel);
    :hover {
        color: var(--link-hover);
        cursor: pointer;
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const BreadcrumbNoLink = styled.div`

`;

export const Container = styled.div`
    margin-left: 10px;

    @media ${device.minwidth.ipad} {
        margin-left: 0px;
    }

    > ${BreadcrumbLink}, div {
        display: inline-block;
        font-family: var(--font-family-semibold);
        font-size: var(--font-size-medium-1);
    }
`;