import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DownloadPayoutCSV, GeneratePayout, UpdatePayoutStatus, ViewTrainerUnpaidPayouts } from '../../Services/Actions';
import moment from 'moment';

import { UnpaidPayout, UnpaidPayoutsContainer, UnpaidPayoutsHeader, UnpaidPayoutsList } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

class Payouts extends React.Component {
    state = { Loading: true, MarkPaidDetails: {}, ShowConfirmMarkPaidPayoutModal: false };

    componentDidMount() {
        this._isMounted = true;
        
        this.onLoadUnpaidPayouts();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickDownloadUnpaidPayout = CSVFileName => {
        this.props.DownloadPayoutCSV({ CSVFileName });
    }

    onClickMarkPaidUnpaidPayout = () => {
        var { CSVFileName, UnpaidPayoutIndex } = this.state.MarkPaidDetails;
        this.props.UpdatePayoutStatus({ CSVFileName, PayoutIndex: UnpaidPayoutIndex });
        this.onToggleShowConfirmMarkPaidPayoutModal({ ShowConfirmMarkPaidPayoutModal: false });
    }

    onGeneratePayout = () => {
        this.props.GeneratePayout().then(() => this.props.ViewTrainerUnpaidPayouts());
    }

    onLoadUnpaidPayouts = () => {
        this.props.ViewTrainerUnpaidPayouts().then(() => this.setState({ Loading: false }));
    }

    onToggleShowConfirmMarkPaidPayoutModal = ({ MarkPaidDetails = {}, ShowConfirmMarkPaidPayoutModal }) => {
        this.setState({ MarkPaidDetails, ShowConfirmMarkPaidPayoutModal });
    }

    renderShowConfirmMarkPaidPayoutModal = () => {
        var { ShowConfirmMarkPaidPayoutModal } = this.state;

        if (ShowConfirmMarkPaidPayoutModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onClickMarkPaidUnpaidPayout}
                    BottomButtonText={t('Payout_Mark_Paid')}
                    Information={t('Payout_Mark_Paid_Confirm')}
                    OnHide={() => this.onToggleShowConfirmMarkPaidPayoutModal({ ShowConfirmMarkPaidPayoutModal: false })}
                    Show={ShowConfirmMarkPaidPayoutModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderUnpaidPayouts = () => {
        var { t } = this.props;
        var { UnpaidPayouts } = this.props;

        return (
            <UnpaidPayoutsContainer className="UnpaidPayoutsContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('Unpaid_payouts')} TextAlign="center" />

                <Spacer Size="medium" />

                <UnpaidPayoutsList className="UnpaidPayoutsList">
                    {
                        UnpaidPayouts.length > 0 ?
                        <>
                            <UnpaidPayoutsHeader className="UnpaidPayoutsHeader">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payout_Date')} TextAlign="center" />
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payout_Amount')} TextAlign="center" />
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payout_Download_Link')} TextAlign="center" />
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payout_Mark_Paid')} TextAlign="center" />
                            </UnpaidPayoutsHeader>
                            {
                                UnpaidPayouts.map(({ CSVFileName, PaymentAmountText, PayoutDate }, UnpaidPayoutIndex) => {
                                    return (
                                        <UnpaidPayout className="UnpaidPayout" key={CSVFileName}>
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(PayoutDate, 'DD/MM/YYYY').format('[WeeklyViewHeader]')} TextAlign="center" />
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={PaymentAmountText} TextAlign="center" />
                                            <ButtonGroup
                                                Buttons={[{ BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onClickDownloadUnpaidPayout(CSVFileName), Title: t('Payout_Download_Now') }]}
                                                ButtonWidth="fit-content"
                                            />
                                            <ButtonGroup
                                                Buttons={[{ BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onToggleShowConfirmMarkPaidPayoutModal({ MarkPaidDetails: { CSVFileName, UnpaidPayoutIndex }, ShowConfirmMarkPaidPayoutModal: true }), Title: t('Payout_Mark_Paid') }]}
                                                ButtonWidth="fit-content"
                                            />
                                        </UnpaidPayout>
                                    );
                                })
                            }
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('No_Unpaid_Payouts')} TextAlign="center" />
                    }
                </UnpaidPayoutsList>
            </UnpaidPayoutsContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { TryingDownloadPayoutCSV, TryingGeneratePayout, TryingGeneratePayoutError, TryingUpdatePayoutStatus, UnpaidPayouts } = this.props;

        return (
            <>
                {(TryingDownloadPayoutCSV || TryingGeneratePayout || TryingUpdatePayoutStatus) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('Payouts')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: this.onGeneratePayout, Title: t('generate_payout') }
                        ]}
                        ButtonWidth="fit-content"
                    />

                    {
                        !!UnpaidPayouts.length &&
                        <>
                            <Spacer Size="extra-extra-small" />

                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('generate_payout_overwrite_previous')} />
                        </>
                    }

                    {
                        TryingGeneratePayoutError &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={TryingGeneratePayoutError} />
                        </>
                    }

                    <Spacer Size="medium" />

                    {this.renderUnpaidPayouts()}
                </Page>

                {this.renderShowConfirmMarkPaidPayoutModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UnpaidPayouts: state.Payouts.UnpaidPayouts,

        TryingDownloadPayoutCSV: state.Payouts.TryingDownloadPayoutCSV,
        TryingDownloadPayoutCSVError: state.Payouts.TryingDownloadPayoutCSVError,
        TryingGeneratePayout: state.Payouts.TryingGeneratePayout,
        TryingGeneratePayoutError: state.Payouts.TryingGeneratePayoutError,
        TryingGetUnpaidPayouts: state.Payouts.TryingGetUnpaidPayouts,
        TryingGetUnpaidPayoutsError: state.Payouts.TryingGetUnpaidPayoutsError,
        TryingUpdatePayoutStatus: state.Payouts.TryingUpdatePayoutStatus,
        TryingUpdatePayoutStatusError: state.Payouts.TryingUpdatePayoutStatusError
    };
};

export default withTranslation()(connect(mapStateToProps, { DownloadPayoutCSV, GeneratePayout, UpdatePayoutStatus, ViewTrainerUnpaidPayouts } )(Payouts));