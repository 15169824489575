import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LoginUser } from '../../Services/Actions';
import history from '../../history';

import { Container, ForgotPasswordImageContainer, LeftArrowContainer } from './styles';

import Button from '../../Components/Buttons/Button'
import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import ForgotPassword from '../../Assets/Illustrations/ForgotPassword.png';
import LeftArrow from '../../Assets/Icons/LeftArrow.png';

import { emailValidator } from '../../Functions';

class Login extends React.Component {
    state = {
        Code: '',
        ConfirmPassword: '',
        DisplayScreen: 'Login',
        Email: '',
        EmailOtp: '',
        IsEmail: false,
        Loading: true,
        LoginType: 'Login',
        NewPassword: '',
        Password: '',
        ValidPassword: false,
        UserId: null,

        Language: 'en'
    };

    componentDidMount() {
        if (this.props.IsLoggedIn) history.push('/');
        else this.setState({ Loading: false });
    }

    onChangeDisplayScreen = DisplayScreen => {
        this.setState({ DisplayScreen });
    }

    onChangeInput = (event, InputName) => {
        var { IsEmail, ValidPassword } = this.state;
        if (InputName === 'Email') IsEmail = emailValidator(event.target.value);
        if (InputName === 'ConfirmPassword') ValidPassword = event.target.value === this.state.NewPassword;
        if (InputName === 'NewPassword') ValidPassword = event.target.value === this.state.ConfirmPassword;

        this.setState({ [`${InputName}`]: event.target.value, IsEmail, ValidPassword });
    }

    onForgotPassword = () => {
        this.onChangeDisplayScreen('NewPassword');
    }

    onKeyPress = event => {
        if (event.key === 'Enter' && !this.props.TryingLoginUser && !this.props.TryingVerifyUser) {
            var { DisplayScreen, Email, EmailOtp, LoginType, Password } = this.state;

            if (DisplayScreen === 'Login' && LoginType === 'Login' && Email && Password) this.onLogin();
            else if (DisplayScreen === 'Login' && LoginType === 'Verify' && EmailOtp) this.onVerify();
            else if (DisplayScreen === 'ForgotPassword' && this.state.Email && this.state.IsEmail) this.onChangeDisplayScreen('NewPassword');
            else if (DisplayScreen === 'NewPassword' && this.state.Code && this.state.NewPassword && this.state.ConfirmPassword && this.state.ValidPassword) this.onChangeDisplayScreen('Login');
        }
    }

    onLogin = () => {
        var { Email, Password } = this.state;

        this.props.LoginUser({ Email, LoginType: 'Login', Password }).then(({ UserId }) => {
            this.setState({ LoginType: 'Verify', UserId });
        });
    }

    onNewPassword = () => {
        this.onChangeDisplayScreen('Login');
    }

    onVerify = () => {
        var { EmailOtp, UserId, Email, Password } = this.state;

        this.props.LoginUser({ Email, Password, EmailOtp, LoginType: 'Verify', UserId });
    }

    renderBackArrow = () => {
        var { DisplayScreen } = this.state;

        if (DisplayScreen === 'Login') return null;

        return (
            <LeftArrowContainer>
                <img src={LeftArrow} alt="Go back" onClick={() => this.onChangeDisplayScreen('Login')} />
            </LeftArrowContainer>
        );
    }

    renderCode = () => {
        var { t } = this.props;

        return (
            <Input
                FontSize="large"
                NoLabel
                OnChange={event => this.onChangeInput(event, 'Code')}
                OnKeyPress={this.onKeyPress}
                Placeholder={t('code_with_first_char_uppercase')}
                Size="extra-large"
                Type="text"
                Value={this.state.Code}
            />
        );
    }

    renderEmail = FromInput => {
        var { t } = this.props;

        var Placeholder = t("email_or_username_with_first_char_uppercase");
        if (FromInput === 'ForgotPassword') Placeholder = t("email_with_first_char_uppercase");

        return (
            <Input
                FontSize="large"
                NoLabel
                OnChange={event => this.onChangeInput(event, 'Email')}
                OnKeyPress={this.onKeyPress}
                Placeholder={Placeholder}
                Size="extra-large"
                Type="text"
                Value={this.state.Email}
            />
        );
    }

    renderEmailOtp = () => {
        var { t } = this.props;

        return (
            <Input
                FontSize="large"
                NoLabel
                OnChange={event => this.onChangeInput(event, 'EmailOtp')}
                OnKeyPress={this.onKeyPress}
                Placeholder={t('code_with_first_char_uppercase')}
                Size="extra-large"
                Type="text"
                Value={this.state.EmailOtp}
            />
        );
    }

    renderForgotPassword = () => {
        var { t } = this.props;

        return (
            <>
                <ForgotPasswordImageContainer>
                    <img src={ForgotPassword} alt={t('RegisterLogin_forgot_password')} />
                </ForgotPasswordImageContainer>
                {this.renderTitle()}
                {this.renderDescription()}

                <Spacer Size="extra-large" />

                <form onSubmit={this.handleSubmit}>
                    {this.renderEmail('ForgotPassword')}

                    <Spacer Size="extra-large" />

                    {this.renderForgotPasswordError()}
                    {this.renderForgotPasswordButton()}
                </form>
            </>
        );
    }

    renderForgotPasswordButton = () => {
        var { t } = this.props;

        var Disabled = false;
        if (!this.state.Email || !this.state.IsEmail) Disabled = true;

        return (
            <Button
                Disabled={Disabled}
                FontSize="large"
                OnClick={Disabled ? null : this.onForgotPassword}
                Title={t('RegisterLogin_reset_password')}
            />
        );
    }

    renderForgotPasswordError = () => {
        if (this.state.Email && !this.state.IsEmail) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="large" Text="Please enter valid email address" />;
        }
    }

    renderLoginButton = () => {
        var { t } = this.props;
        var { TryingLoginUser, TryingVerifyUser } = this.props;
        var { Email, EmailOtp, LoginType, Password } = this.state;

        var Disabled = false;
        if (TryingLoginUser || TryingVerifyUser || (LoginType === 'Login' && (!Email || !Password)) || (LoginType === 'Verify' && !EmailOtp)) Disabled = true;

        // var OnClick = null;
        // if (!Disabled && LoginType === 'Login') OnClick = this.onLogin;
        // else if (!Disabled && LoginType === 'Verify') OnClick = this.onVerify;

        return (
            <Button
                Disabled={Disabled}
                FontSize="large"
                OnClick={Disabled ? null : LoginType === 'Login' ? event => this.onLogin(event) : event => this.onVerify(event)}
                Title={t('login_with_first_char_uppercase')}
            />
        );
    }

    renderLoginError = () => {
        if (this.props.TryingLoginUserError || this.props.TryingVerifyUserError) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="large" Text={this.props.TryingLoginUserError || this.props.TryingVerifyUserError} />;
        }
    }

    renderLoginForm = () => {
        var { LoginType } = this.state;

        return (
            <>
                {
                    LoginType === 'Login' ?
                    <>
                        {this.renderEmail()}
                        {this.renderPassword()}
                    </>
                :
                    this.renderEmailOtp()
                }

                <Spacer Size="extra-large" />

                {this.renderLoginError()}
                {this.renderLoginButton()}
            </>
        );
    }

    renderNewPasswordButton = () => {
        var { t } = this.props;

        var Disabled = false;
        if (!this.state.Code || !this.state.NewPassword || !this.state.ConfirmPassword || !this.state.ValidPassword) Disabled = true;

        return (
            <Button
                Disabled={Disabled}
                FontSize="large"
                OnClick={Disabled ? null : this.onNewPassword}
                Title={t('RegisterLogin_reset_password')}
            />
        );
    }

    renderNewPasswordError = () => {
        if (this.state.ConfirmPassword && this.state.NewPassword && !this.state.ValidPassword) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="large" Text="Your passwords do not match" />;
        }
    }

    renderNewPasswordForm = () => {
        return (
            <>
                {this.renderTitle()}
                {this.renderDescription()}

                <Spacer Size="extra-large" />

                <form onSubmit={this.handleSubmit}>
                    {this.renderCode()}

                    <Spacer Size="extra-large" />

                    {this.renderPassword('NewPassword')}
                    {this.renderPassword('ConfirmPassword')}

                    <Spacer Size="extra-large" />

                    {this.renderNewPasswordError()}
                    {this.renderNewPasswordButton()}
                </form>
            </>
        );
    }

    renderPassword = PasswordType => {
        var { t } = this.props;

        var InputName = PasswordType || 'Password';
        var Value = this.state[InputName];
        var Placeholder = PasswordType ? PasswordType === 'NewPassword' ? t('Profile_new_password') : t('confirm_password_with_first_char_uppercase') : t('password_with_first_char_uppercase');

        return (
            <Input
                FontSize="large"
                NoLabel
                OnChange={event => this.onChangeInput(event, InputName)}
                OnKeyPress={this.onKeyPress}
                Placeholder={Placeholder}
                Size="extra-large"
                Type="password"
                Value={Value}
            />
        );
    }

    onChangeLanguage = () => {
        if (this.state.Language === 'en') {
            this.setState({ Language: 'no' }, () => {
                this.props.i18n.changeLanguage('no');
            });
        } else if (this.state.Language === 'no') {
            this.setState({ Language: 'en' }, () => {
                this.props.i18n.changeLanguage('en');
            });
        }
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { DisplayScreen } = this.state;

        return (
            <Container>
                {(this.props.TryingVerifyUser) && <Loading />}

                {this.renderBackArrow()}
                
                {
                    DisplayScreen === 'Login' ?
                    this.renderLoginForm()
                :
                    DisplayScreen === 'ForgotPassword' ?
                    this.renderForgotPassword()
                :
                    DisplayScreen === 'NewPassword' ?
                    this.renderNewPasswordForm()
                :
                    null
                }
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        IsLoggedIn: state.Auth.IsLoggedIn,

        TryingLoginUser: state.Auth.TryingLoginUser,
        TryingLoginUserError: state.Auth.TryingLoginUserError,
        TryingVerifyUser: state.Auth.TryingVerifyUser,
        TryingVerifyUserError: state.Auth.TryingVerifyUserError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { LoginUser } )(Login));