import axios from 'axios';
import history from '../../history';
import { RIDGE_API, TOKEN_NAME } from '../../Config';

import {
    HANDLE_VISIBILITY_CHANGE,
    RESIZE_WINDOW,

    TRYING_DOWNLOAD_PAYOUT_CSV, TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS, TRYING_DOWNLOAD_PAYOUT_CSV_ERROR,
    TRYING_GENERATE_PAYOUT, TRYING_GENERATE_PAYOUT_SUCCESS, TRYING_GENERATE_PAYOUT_ERROR,
    TRYING_GET_UNPAID_PAYOUTS, TRYING_GET_UNPAID_PAYOUTS_SUCCESS, TRYING_GET_UNPAID_PAYOUTS_ERROR,
    TRYING_LOGIN_USER, TRYING_LOGIN_USER_SUCCESS, TRYING_LOGIN_USER_ERROR, LOGOUT_USER,
    TRYING_UPDATE_PAYOUT_STATUS, TRYING_UPDATE_PAYOUT_STATUS_SUCCESS, TRYING_UPDATE_PAYOUT_STATUS_ERROR,
    TRYING_VERIFY_USER, TRYING_VERIFY_USER_SUCCESS, TRYING_VERIFY_USER_ERROR
} from '../Types';

export const CheckLogin = () => async dispatch => {
    var Token = localStorage.getItem(TOKEN_NAME);

    if (Token) {
        dispatch({ type: TRYING_LOGIN_USER });

        var AuthToken = { headers: { 'Authorization': 'Bearer ' + Token } };
        const response = await axios.post(`${RIDGE_API}CheckLogin`, {}, AuthToken);
        
        if (response.data.Response === 1) {
            dispatch({ type: TRYING_LOGIN_USER_SUCCESS });
            dispatch({ type: TRYING_VERIFY_USER_SUCCESS });
        } else {
            dispatch({ type: TRYING_LOGIN_USER_ERROR, payload: response.data.ErrorMessage });

            if (response.data.BadToken) {
                LogoutUserFunc();

                dispatch({ type: LOGOUT_USER });
            }
        }
    }
    else {
        dispatch({ type: LOGOUT_USER });
        history.push('/login');
    }
}

export const DownloadPayoutCSV = ({ CSVFileName }) => async dispatch => {
    dispatch({ type: TRYING_DOWNLOAD_PAYOUT_CSV });
    var AuthToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME) } };

    var data = { CSVFileName };

    const response = await axios.post(`${RIDGE_API}DownloadPayoutCSV`, data, AuthToken);

    if (response.data.Response === 1) {
        var { FileUrl } = response.data;

        var saveData = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            
            return function () {
                a.href = FileUrl;
                a.download = CSVFileName;
                a.click();
            };
        }());

        saveData();

        dispatch({ type: TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS });
    } else {
        dispatch({ type: TRYING_DOWNLOAD_PAYOUT_CSV_ERROR, payload: response.data.ErrorMessage });

        if (response.data.BadToken) LogoutUser();
    }
}

export const GeneratePayout = () => async dispatch => {
    dispatch({ type: TRYING_GENERATE_PAYOUT });
    var AuthToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME) } };

    const response = await axios.post(`${RIDGE_API}GeneratePayout`, null, AuthToken);

    if (response.data.Response === 1) {
        var { FileUrl } = response.data;

        var saveData = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            
            return function () {
                a.href = FileUrl;
                // a.download = CSVFileName;
                a.click();
            };
        }());

        saveData();

        dispatch({ type: TRYING_GENERATE_PAYOUT_SUCCESS });
    } else {
        dispatch({ type: TRYING_GENERATE_PAYOUT_ERROR, payload: response.data.ErrorMessage });

        if (response.data.BadToken) LogoutUser();
    }
}

export const HandleVisibilityChange = () => async dispatch => {
    dispatch({ type: HANDLE_VISIBILITY_CHANGE });
}

export const LoginUser = ({ Email, EmailOtp, LoginType, Password, UserId }) => async dispatch => {
    if (LoginType === 'Login') dispatch({ type: TRYING_LOGIN_USER });
    else dispatch({ type: TRYING_VERIFY_USER });
  
    var data = { Email, EmailOtp, LoginType, Password, UserId };

    const response = await axios.post(`${RIDGE_API}LoginUser`, { ...data });
  
    if (response.data.Response === 1) {
        if (LoginType === 'Login') dispatch({ type: TRYING_LOGIN_USER_SUCCESS });
        else dispatch({ type: TRYING_VERIFY_USER_SUCCESS });

        if (LoginType === 'Login') {
            var { UserId: NewUserId } = response.data;

            return { UserId: NewUserId };
        }
        else if (LoginType === 'Verify') {
            var { Token } = response.data;
    
            localStorage.setItem(TOKEN_NAME, Token);

            history.push('/');
        }
    }
    else {
        if (LoginType === 'Login') dispatch({ type: TRYING_LOGIN_USER_ERROR, payload: response.data.ErrorMessage });
        else dispatch({ type: TRYING_VERIFY_USER_ERROR, payload: response.data.ErrorMessage });
    }
}

export const LogoutUser = () => async dispatch => {
    LogoutUserFunc();
    dispatch({ type: LOGOUT_USER });
}

function LogoutUserFunc() {
    localStorage.removeItem(TOKEN_NAME);
    history.push('/login');
}

export const ResizeWindow = () => async dispatch => {
    dispatch({ type: RESIZE_WINDOW });
}

export const SaveDataInProps = SaveData => async dispatch => {
    // dispatch({ type: SAVE_DATA_IN_PROPS, payload: SaveData });
}

export const UpdatePayoutStatus = ({ CSVFileName, PayoutIndex }) => async dispatch => {
    dispatch({ type: TRYING_UPDATE_PAYOUT_STATUS });
    var AuthToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME) } };

    var data = { CSVFileName };

    const response = await axios.post(`${RIDGE_API}UpdatePayoutStatus`, data, AuthToken);

    if (response.data.Response === 1) {
        dispatch({ type: TRYING_UPDATE_PAYOUT_STATUS_SUCCESS, payload: PayoutIndex });
    } else {
        dispatch({ type: TRYING_UPDATE_PAYOUT_STATUS_ERROR, payload: response.data.ErrorMessage });

        if (response.data.BadToken) LogoutUser();
    }
}

export const UpdateSelectedTab = SelectedTab => async dispatch => {
    // dispatch({ type: UPDATE_SELECTED_TAB, payload: SelectedTab });
}

export const ViewTrainerUnpaidPayouts = () => async dispatch => {
    dispatch({ type: TRYING_GET_UNPAID_PAYOUTS });
    var AuthToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME) } };

    const response = await axios.post(`${RIDGE_API}ViewTrainerUnpaidPayouts`, null, AuthToken);

    if (response.data.Response === 1) {
        var { Payouts } = response.data;

        dispatch({ type: TRYING_GET_UNPAID_PAYOUTS_SUCCESS, payload: Payouts });
    } else {
        dispatch({ type: TRYING_GET_UNPAID_PAYOUTS_ERROR, payload: response.data.ErrorMessage });

        if (response.data.BadToken) LogoutUser();
    }
}