import styled from 'styled-components';

export const UnpaidPayout = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 15px;

    :nth-child(odd) {
        background-color: var(--light-gray);
    }

    :not(:last-child) {
        border-bottom: none;
    }

    :last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const UnpaidPayoutsContainer = styled.div``;

export const UnpaidPayoutsHeader = styled.div`
    align-items: center;
    background-color: var(--blue-denim);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 15px;
`;

export const UnpaidPayoutsList = styled.div``;