import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import English from './English.json';
import Norwegian from './Norwegian.json';

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // we init with resources
    resources: {
        en: { translation: English },
        no: { translation: Norwegian },
    },
    fallbackLng: "en",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false
    }
});

export default i18n;
