import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { StyledTopBar } from './styles';

import PageText from '../Text/PageText';

class TopBar extends React.Component {
    state = { CanToggle: false, ShowHoverItems: false };

    componentDidMount() {
        var { ClientColor, ShowClientOptions } = this.props;

        if (ShowClientOptions && !ClientColor) this.setState({ ShowHoverItems: true });
        else if (ShowClientOptions) this.setState({ CanToggle: true });
    }

    onToggleShowHoverItems = ShowHoverItems => {
        if (this.state.CanToggle) this.setState({ ShowHoverItems });
    }

    renderHoverItems = () => {
        var { t } = this.props;
        var { ClientColor, OnChangeColor, OnDeleteClient } = this.props;

        return (
            <>
                <PageText FontColor="white" FontSize="small" NoMargin OnClick={OnDeleteClient} Text={t('ptadmin_clients_delete_singular')} />
                <PageText FontColor="white" FontSize="small" NoMargin OnClick={OnChangeColor} Text={ClientColor ? t('color_change') : t('color_add')} />
            </>
        );
    }

    render() {
        var { BackgroundColor } = this.props;
        var { ShowHoverItems } = this.state;

        return (
            <StyledTopBar
                BackgroundColor={BackgroundColor}
                onMouseEnter={() => this.onToggleShowHoverItems(true)}
                onMouseLeave={() => this.onToggleShowHoverItems(false)}
            >
                {
                    ShowHoverItems ?
                    this.renderHoverItems()
                :
                    null
                }
            </StyledTopBar>
        );
    }
}

TopBar.propTypes = {
    BackgroundColor: PropTypes.string.isRequired,
    ClientColor: PropTypes.string,
    OnChangeColor: PropTypes.func,
    OnDeleteClient: PropTypes.func,
    ShowClientOptions: PropTypes.bool
}

export default withTranslation()(TopBar);