import React from 'react';
import PropTypes from 'prop-types';

import { ChildrenContainer, StyledModal } from './styles';

import Button from '../Buttons/Modal';
import { GA_ModalView } from '../GoogleAnalytics';
import Spacer from '../Spacer'; 

class AbelModal extends React.Component {
    componentDidMount() {
        if (this.props.GA_PathName) GA_ModalView(this.props.GA_PathName);
    }

    render() {
        var { BottomButton, BottomButtonOnClick, BottomButtonRender, BottomButtonText, children, className, Disabled, DisplayNone, NoButtons, NoMargin, OnHide, Show, Size, TopButton, TopButtonOnClick, TopButtonText } = this.props;

        if (NoButtons) {
            return (
                <StyledModal dialogClassName={`modal-size-${Size.toLowerCase()} ${DisplayNone ? 'display-none' : ''} ${className}`} show={Show} onHide={OnHide} aria-labelledby="contained-modal-title-vcenter" centered>
                    <ChildrenContainer NoMargin={NoMargin}>
                        {children}
                    </ChildrenContainer>
                </StyledModal>
            );
        }

        return (
            <StyledModal dialogClassName={`modal-size-${Size.toLowerCase()} ${DisplayNone ? 'display-none' : ''} ${className}`} show={Show} onHide={OnHide} aria-labelledby="contained-modal-title-vcenter" centered>
                {
                    TopButton ?
                    <Button ButtonText={TopButtonText} Position="Top" OnClick={TopButtonOnClick || null} Type={TopButton} />
                :
                    null
                }

                <Spacer Size="medium" />

                <ChildrenContainer NoMargin={NoMargin}>
                    {children}
                </ChildrenContainer>

                <Spacer Size="medium" />

                {
                    BottomButton ?
                    <Button ButtonText={BottomButtonText} Disabled={Disabled} Position="Bottom" OnClick={BottomButtonOnClick ? Disabled ? () => null : BottomButtonOnClick : null} Type={BottomButton} />
                :
                    BottomButtonRender ?
                    BottomButtonRender()
                :
                    null
                }
            </StyledModal>
        );
    }
}

AbelModal.propTypes = {
    BottomButton: PropTypes.string,
    BottomButtonOnClick: PropTypes.func,
    BottomButtonRender: PropTypes.func,
    BottomButtonText: PropTypes.string,
    Disabled: PropTypes.bool,
    DisplayNone: PropTypes.bool,
    GA_PathName: PropTypes.string,
    NoButtons: PropTypes.bool,
    NoMargin: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    Size: PropTypes.string.isRequired,
    TopButton: PropTypes.string,
    TopButtonOnClick: PropTypes.func,
    TopButtonText: PropTypes.string
}

export default AbelModal;