import styled from 'styled-components';

import { device } from '../../Config/device';

import Button from '../Buttons/Button';

export const Container = styled.div`
    margin: ${props => props.NoMargin ? '0' : 'var(--spacer-size-medium)'} auto;

    @media ${device.minwidth.mobile_small} { width: 100%; }
    @media ${device.minwidth.mobile} { width: 100%; }
    @media ${device.minwidth.ipad} { width: 87%; }
    @media ${device.minwidth.laptop} { width: 85%; }
`;

// Container width: ${props => props.PageWidth ? `${props.PageWidth}%` : props.NoMargin ? '100%' : '85%'};

export const MainContentContainer = styled.div`
    margin: var(--spacer-size-small) auto;

    @media ${device.minwidth.mobile_small} { width: 93%; }
    @media ${device.minwidth.mobile} { margin: var(--spacer-size-medium) auto; width: 90%; }
    @media ${device.minwidth.ipad} { width: 80%; }
    @media ${device.minwidth.laptop} { width: 80%; }
`;

// MainContentContainer width: ${props => props.ContentWidth ? props.ContentWidth : '85%'};

export const PageContainer = styled.div`
    background-color: ${props => props.NoBackground ? 'inherit' : 'var(--white)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
`;

export const SplitButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--gray)' : props.ButtonName === 'One' ? 'var(--blue-astronaut)' : 'var(--blue-abel)'};
    color: ${props => props.Disabled ? 'var(--black)' : 'var(--white)'};
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-3);
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px 30px;

    @media(hover:hover) {
        :hover {
            background-color: ${props => props.Disabled ? 'var(--dark-gray)' : props.ButtonName === 'One' ? 'var(--blue-astronaut-hover)' : 'var(--abel-blue-hover)'};
            cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
        }
    }
`;

export const SplitButtonsContainer = styled.div`
    align-items: stretch;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
`;

export const StyledSubmitButton = styled(Button)`
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
`;