import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: ${props => props.JustifyContent ? props.JustifyContent.toLowerCase() : 'center'};
    margin-bottom: ${props => props.NoMargin ? '0px' : 'var(--spacer-size-extra-small)'};
    ${({ TextAlign }) => TextAlign ? `text-align: ${TextAlign.toLowerCase()};` : null}
    ${({ ContainerWidth }) => ContainerWidth ? `margin-left: auto; margin-right: auto; width: ${ContainerWidth};` : null}
    max-width: 100%;
    ${({ NoWrap }) => NoWrap ? 'white-space: nowrap;' : null}
    ${({ WhiteSpace }) => WhiteSpace ? `white-space: ${WhiteSpace};` : null}
    word-break: ${props => props.WordBreak ? props.WordBreak.toLowerCase() : 'break-word'};
`;

export const TitleText = styled.div`
    ${({ FontColor }) => (FontColor ? `color: var(--${FontColor.toLowerCase()});` : null)}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-semibold)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-large)'};
    ${props => props.Underline && 'text-decoration: underline;'}
    ${props => props.Lowercase && `text-transform: lowercase;`}
    ${props => props.Uppercase && `text-transform: uppercase;`}

    ${({ Clickable }) => (
        Clickable ?
        `@media(hover:hover) {
            :hover {
                cursor: pointer;
            }
        }`
    :
        null
    )}

    ${({ FontColorHover }) => (
        FontColorHover ?
        `@media(hover:hover) {
            :hover {
                color: var(--${FontColorHover.toLowerCase()});
            }
        }`
    :
        null
    )}
`;

export const TitleTextSpan = styled.span`
    ${({ FontColor }) => (FontColor ? `color: var(--${FontColor.toLowerCase()});` : null)}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-semibold)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-large)'};
    ${props => props.Underline && 'text-decoration: underline;'}
    ${props => props.Lowercase && `text-transform: lowercase;`}
    ${props => props.Uppercase && `text-transform: uppercase;`}
    ${({ NoWrap }) => NoWrap ? 'white-space: nowrap;' : null}
    ${({ WhiteSpace }) => WhiteSpace ? `white-space: ${WhiteSpace};` : null}
    word-break: ${props => props.WordBreak ? props.WordBreak.toLowerCase() : 'break-word'};

    ${({ Clickable }) => (
        Clickable ?
        `@media(hover:hover) {
            :hover {
                cursor: pointer;
            }
        }`
    :
        null
    )}

    ${({ FontColorHover }) => (
        FontColorHover ?
        `@media(hover:hover) {
            :hover {
                color: var(--${FontColorHover.toLowerCase()});
            }
        }`
    :
        null
    )}
`;