import styled from 'styled-components';
import { getBorderRadiusFromHeight } from '../../../Functions';

export const InputContainer = styled.div`
    margin-bottom: ${props => props.NoMargin ? '0px' : 'var(--spacer-size-extra-small)'};

    text-align: ${props => props.Centered ? 'center' : 'left'};
`;

export const InputElement = styled.input`
    background-color: ${props => props.readOnly ? 'var(--white-concrete)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: ${props => props.size ? getBorderRadiusFromHeight(props.size.toLowerCase()) : '21px'};
    color: ${props => props.FontColor ? `var(--${props.FontColor})` : 'var(--black)'};
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-medium)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-medium-1)'};
    line-height: 1.25rem;
    padding: 5px 10px;
    text-indent: ${props => props.type === 'file' ? '0px' : '10px'};
    height: ${props => props.size ? props.size === 'auto' ? 'auto' : `var(--input-size-${props.size.toLowerCase()})` : 'var(--input-size-small)'};
    outline: none;
    width: ${props => props.Centered ? 'auto' : '100%'};

    ::placeholder {
        color: var(--gray-silver);
        opacity: 1;
    }
    
    :-ms-input-placeholder {
        color: var(--gray-silver);
    }
    
    ::-ms-input-placeholder {
        color: var(--gray-silver);
    }

    @media(hover:hover) {
        :hover {
            cursor: ${props => props.readOnly ? 'cursor' : 'auto'};
        }
    }
`;

export const InputLabel = styled.div`
    font-family: var(--font-family-semibold);
    margin-bottom: 10px;
`;