import styled from 'styled-components';

import { device } from '../../Config/device';

export const AbelHeader = styled.div`
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: ${props => props.IsHeaderMobile ? '0px' : '20px 0px'};
`;

export const AbelHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    ${props => !props.IsHeaderMobile ?
        `
            @media ${device.minwidth.ipad} { width: 87%; }
            @media ${device.minwidth.laptop} { width: 85%; }
        `
    :
        null
    }
`;

export const AbelLogo = styled.img`
    height: ${props => props.IsHeaderMobile ? '28px' : '50px'};
    width: ${props => props.IsHeaderMobile ? '30px' : '55px'};
`;

export const AbelLogoContainer = styled.div`
    display: flex;
    margin-right: 50px;

    @media ${device.maxwidth.ipad} {
        margin-right: 30px;
    }
`;

export const AbelLogoImageContainer = styled.div`
    margin-right: 10px;
`;

export const AbelNameContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: ${props => props.IsHeaderMobile ? 'row' : 'column'};
`;

export const LeftMenu = styled.div`
    align-items: center;
    display: flex;
`;

export const MenuContainer = styled.div`
    display: flex;

    > a {
        align-items: center;
        display: flex;

        :not(:last-child) {
            margin-right: 50px;
        }
    }

    @media ${device.maxwidth.ipad} {
        > a {
            :not(:last-child) {
                margin-right: 30px;
            }
        }
    }
`;

export const MenuLink = styled.a`
    ${props => props.ActiveLink ? `color: var(--blue-abel);` : null}
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-2);

    :active {
        color: var(--blue-abel);
    }

    :visited {
        color: var(--blue-abel);
    }

    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }
`;

export const MobileHeaderContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const MobileHeaderTopContainer = styled.div`
    align-items: center;    
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;
`;

export const RightMenu = styled.div`
    align-items: center;
    display: flex;
`;