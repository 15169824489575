import styled from 'styled-components';

export const ButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--gray-silver);' : props.Selected ? props.BackgroundColorSelected ? `var(--${props.BackgroundColorSelected})` : 'var(--blue-astronaut)' : props.BackgroundColor ? `var(--${props.BackgroundColor})` : 'var(--white)'};
    border: ${props => props.Border ? props.Border : '2px solid var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Disabled ? 'var(--black);' : props.Selected ? props.ColorSelected ? `var(--${props.ColorSelected})` : 'var(--white)' : props.Color ? `var(--${props.Color})` : 'var(--black)'};
    display: inline-flex;
    flex-direction: ${props => props.IconPosition === 'top' ? 'column' : 'row'};
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-medium)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-medium-1)'};
    justify-content: center;
    ${({ NotTouching, OwnRows }) => (
        NotTouching && !OwnRows ?
            `
                :not(:first-child) {
                    margin-left: 10px;
                }
            `
        :
            null
    )}
    ${({ OwnRows }) => (
        OwnRows ?
            `
                :not(:first-child) {
                    margin-top: 10px;
                }
            `
        :
            null
    )}
    min-height: var(--button-height-${props => props.Size ? props.Size : 'medium'});
    padding: 5px 10px;
    position: relative;
    text-align: ${props => props.TextAlign ? props.TextAlign : 'center'};
    width: ${props => props.ButtonWidth ? props.ButtonWidth : '100%'};
    white-space: ${props => props.ButtonWidth || props.NormalWhiteSpace ? 'normal' : 'nowrap'};
    word-break: break-word;

    ${({ NotTouching }) => (
        NotTouching ?
            null
        :
            `
                border-radius: 0px;

                :first-child {
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                }
            
                :last-child {
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            
                :not(:last-child) {
                    border-right: none;
                }
            `
    )}

    @media (hover:hover) {
        :hover {
            ${props => props.Disabled ? 'background-color: var(--gray-silver);' : props.BackgroundColorHover ? `background-color: var(--${props.BackgroundColorHover});` : props.IsButton ? props.IsSelected ? 'background-color: var(--white-concrete);' : 'background-color: var(--white-concrete);' : null}
            ${props => !!!props.Disabled && props.BorderHover && `border: ${props.BorderHover};`}
            ${props => props.Disabled ? 'color: var(--black);' : props.ColorHover ? `color: var(--${props.ColorHover});` : props.IsButton ? props.IsSelected ? 'color: var(--black);' : 'color: var(--black);' : null}
            ${props => props.Disabled ? 'cursor: not-allowed' : props.IsButton ? 'cursor: pointer;' : null};
        }
    }
`;

export const ButtonIcon = styled.img`
    ${props => props.IconSize ? `height: ${props.IconSize.Height}` : 'max-height: 25px'};
    margin-right: 10px;
    ${props => props.IconSize ? `width: ${props.IconSize.Width}` : 'max-width: 25px'};
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.OwnRows ? 'column' : 'row'};
    justify-content: ${props => props.FitContent ? 'center' : 'space-evenly'};
    ${props => props.ContainerWidth ? `width: ${props.ContainerWidth};` : null}
`;

// width: ${props => props.FitContent ? 'fit-content' : 'auto'};

export const StyledInput = styled.input`
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media (hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;