import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LogoutUser } from '../../Services/Actions';
import history from '../../history';

import { AbelHeader, AbelHeaderContainer, AbelLogo, AbelLogoContainer, AbelLogoImageContainer, AbelNameContainer, LeftMenu, MenuContainer, MenuLink, MobileHeaderContainer, MobileHeaderTopContainer, RightMenu } from './styles';

import PageText from '../Text/PageText';

import ModalMenu from '../../Modals/Menu';

var AbelIcon = require('../../Assets/Icons/Abel_HeaderIcon.png');

class Header extends React.Component {
    state = { ShowMenuModal: false };

    onClickImage = () => {
        if (history.location.pathname !== '/') history.push('/');
    }

    onClickLogout = () => {
        this.onToggleShowMenuModal(false);
        this.props.LogoutUser();
    }

    onToggleShowMenuModal = ShowMenuModal => {
        this.setState({ ShowMenuModal });
    }

    renderLaptopHeader = () => {
        var { t } = this.props;

        return (
            <>
                <LeftMenu>
                    {this.renderLogo()}
                </LeftMenu>
                <RightMenu>
                    <MenuContainer>
                        <MenuLink href={null} onClick={() => this.onToggleShowMenuModal(true)}>
                            {t('Profile_Log_Out')}
                        </MenuLink>
                    </MenuContainer>
                </RightMenu>
            </>
        );
    }

    renderLogo = () => {
        var { IsHeaderMobile } = this.props;

        return (
            <AbelLogoContainer>
                <AbelLogoImageContainer onClick={this.onClickImage}>
                    <AbelLogo src={AbelIcon} alt="ABEL - The Digital Personal Trainer" IsHeaderMobile={IsHeaderMobile} />
                </AbelLogoImageContainer>
                <AbelNameContainer IsHeaderMobile={IsHeaderMobile}>
                    <PageText ElementType={IsHeaderMobile ? 'span' : 'div'} FontFamily="semibold" FontSize={IsHeaderMobile ? 'medium-1half' : 'medium-3'} JustifyContent="flex-start" NoMargin Text="ABEL" TextAlign="left" />
                    <PageText ElementType={IsHeaderMobile ? 'span' : 'div'} FontFamily="medium" FontSize={IsHeaderMobile ? 'small' : 'medium-1half'} JustifyContent="flex-start" NoMargin Text="Payouts" TextAlign="left" />
                </AbelNameContainer>
            </AbelLogoContainer>
        );
    }

    renderMobileHeader = () => {
        return (
            <MobileHeaderContainer>
                <MobileHeaderTopContainer>
                    <LeftMenu>
                        {this.renderLogo()}
                    </LeftMenu>
                </MobileHeaderTopContainer>
            </MobileHeaderContainer>
        );
    }

    renderModal = () => {
        var { t } = this.props;
        var { ShowMenuModal } = this.state;

        var MenuItems = [
            { Name: t('Profile_Log_Out'), OnClick: this.onClickLogout }
        ];

        if (ShowMenuModal) {
            return <ModalMenu MenuItems={MenuItems} Show={ShowMenuModal} OnHide={() => this.onToggleShowMenuModal(false)} />;
        }
    }

    render() {
        var { IsHeaderMobile } = this.props;

        return (
            <>
                <AbelHeader IsHeaderMobile={IsHeaderMobile}>
                    <AbelHeaderContainer IsHeaderMobile={IsHeaderMobile}>
                        {
                            IsHeaderMobile ?
                            this.renderMobileHeader()
                        :
                            this.renderLaptopHeader()
                        }
                    </AbelHeaderContainer>
                </AbelHeader>

                {this.renderModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        IsHeaderMobile: state.Window.IsHeaderMobile,
        IsVerySmall: state.Window.IsVerySmall,
        
        IsLoggedIn: state.Auth.IsLoggedIn,
        UserDetails: state.Auth.UserDetails,

        TryingLoginUser: state.Auth.TryingLoginUser
    };
};
  
export default withTranslation()(connect(mapStateToProps, { LogoutUser } )(Header));