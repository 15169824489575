import {
    HANDLE_VISIBILITY_CHANGE,
    RESIZE_WINDOW
} from '../Types';

const INITIAL_STATE = {
    Device: null,
    IsHeaderMobile: null,
    IsVerySmall: null,
    WindowVisible: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HANDLE_VISIBILITY_CHANGE:
            return { ...state, WindowVisible: document.visibilityState === 'visible' ? true : false };

        case RESIZE_WINDOW:
            var Device = null;
            var IsHeaderMobile = null;
            var IsVerySmall = null;

            var InnerWidth = window.innerWidth;

            if (InnerWidth >= 1126) Device = 'laptop';
            else if (InnerWidth >= 701) Device = 'ipad';
            else if (InnerWidth >= 451) Device = 'mobile';
            else Device = 'mobile_small';

            if (InnerWidth >= 901) IsHeaderMobile = false;
            else IsHeaderMobile = true;

            if (InnerWidth >= 351) IsVerySmall = false;
            else IsVerySmall = true;

            return { ...state, Device, IsHeaderMobile, IsVerySmall };
        default:
            return state;
    }
};
