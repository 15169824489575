import React from 'react';
import PropTypes from 'prop-types';

import { InputContainer, InputElement, InputLabel } from './styles';

class Input extends React.Component {
    onChange = event => {
        var { NoDecimal, OnChange, Type } = this.props;

        var Value = event.target.value;

        if (Type.toLowerCase() === 'number') {
            if (!NoDecimal && ((/\d+\.?\d*/).test(Value) || Number(Value) === 0)) OnChange(event);
            else if (/^\d+$/.test(Value) || Number(Value) === 0) OnChange(event);
            
            // if (/^[0-9]+(\.[0-9]{1,2})?$/.test(Value) || Number(Value) === 0) OnChange(event);
            // if (/^\d+$/.test(Value) || Number(Value) === 0) OnChange(event);
        } else OnChange(event);
    }

    render() {
        var { Accept, Centered, Checked, className, Disabled, FontColor, FontFamily, FontSize, HtmlFor, Id, Label, NoLabel, NoMargin, OnBlur, OnKeyPress, Placeholder, ReadOnly, Size, Type, Value } = this.props;

        return (
            <InputContainer className={className} Centered={Centered} NoMargin={NoMargin}>
                {
                    !NoLabel ?
                    <InputLabel>{Label}</InputLabel>
                :
                    null
                }
                <InputElement
                    accept={Accept}
                    Centered={Centered}
                    checked={Checked}
                    disabled={Disabled ? 'disabled' : null}
                    FontColor={FontColor}
                    FontFamily={FontFamily}
                    FontSize={FontSize}
                    htmlFor={HtmlFor}
                    id={Id}
                    onBlur={OnBlur}
                    onChange={this.onChange}
                    onKeyPress={OnKeyPress}
                    placeholder={Placeholder}
                    readOnly={ReadOnly}
                    size={Size}
                    type={Type}
                    value={Value !== null ? Value : ''}
                />
            </InputContainer>
            
        );
    }
}

Input.propTypes = {
    Accept: PropTypes.string,
    Centered: PropTypes.bool,
    Checked: PropTypes.bool,
    Disabled: PropTypes.bool,
    FontColor: PropTypes.string,
    FontFamily: PropTypes.string,
    FontSize: PropTypes.string,
    HtmlFor: PropTypes.string,
    Id: PropTypes.string,
    Label: PropTypes.string,
    NoDecimal: PropTypes.bool,
    NoLabel: PropTypes.bool,
    NoMargin: PropTypes.bool,
    OnBlur: PropTypes.func,
    OnChange: PropTypes.func.isRequired,
    OnKeyPress: PropTypes.func,
    Placeholder: PropTypes.string,
    ReadOnly: PropTypes.bool,
    Size: PropTypes.string,
    Type: PropTypes.string.isRequired,
    Value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
};

export default Input;