export const HANDLE_VISIBILITY_CHANGE = 'HANDLE_VISIBILITY_CHANGE';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';

export const TRYING_DOWNLOAD_PAYOUT_CSV = 'TRYING_DOWNLOAD_PAYOUT_CSV';
export const TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS = 'TRYING_DOWNLOAD_PAYOUT_CSV_SUCCESS';
export const TRYING_DOWNLOAD_PAYOUT_CSV_ERROR = 'TRYING_DOWNLOAD_PAYOUT_CSV_ERROR';

export const TRYING_GENERATE_PAYOUT = 'TRYING_GENERATE_PAYOUT';
export const TRYING_GENERATE_PAYOUT_SUCCESS = 'TRYING_GENERATE_PAYOUT_SUCCESS';
export const TRYING_GENERATE_PAYOUT_ERROR = 'TRYING_GENERATE_PAYOUT_ERROR';

export const TRYING_GET_UNPAID_PAYOUTS = 'TRYING_GET_UNPAID_PAYOUTS';
export const TRYING_GET_UNPAID_PAYOUTS_SUCCESS = 'TRYING_GET_UNPAID_PAYOUTS_SUCCESS';
export const TRYING_GET_UNPAID_PAYOUTS_ERROR = 'TRYING_GET_UNPAID_PAYOUTS_ERROR';

export const TRYING_LOGIN_USER = 'TRYING_LOGIN_USER';
export const TRYING_LOGIN_USER_SUCCESS = 'TRYING_LOGIN_USER_SUCCESS';
export const TRYING_LOGIN_USER_ERROR = 'TRYING_LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

export const TRYING_UPDATE_PAYOUT_STATUS = 'TRYING_UPDATE_PAYOUT_STATUS';
export const TRYING_UPDATE_PAYOUT_STATUS_SUCCESS = 'TRYING_UPDATE_PAYOUT_STATUS_SUCCESS';
export const TRYING_UPDATE_PAYOUT_STATUS_ERROR = 'TRYING_UPDATE_PAYOUT_STATUS_ERROR';

export const TRYING_VERIFY_USER = 'TRYING_VERIFY_USER';
export const TRYING_VERIFY_USER_SUCCESS = 'TRYING_VERIFY_USER_SUCCESS';
export const TRYING_VERIFY_USER_ERROR = 'TRYING_VERIFY_USER_ERROR';