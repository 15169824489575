import React from 'react';
import PropTypes from 'prop-types';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';

class Info extends React.Component {
    render() {
        var { BottomButton, BottomButtonDisabled, BottomButtonOnClick, BottomButtonText, className, DisplayNone, Information, InformationRenderer, OnHide, Show, Size, TextAlign } = this.props;

        return (
            <AbelModal
                className={className}
                BottomButton={BottomButton || null}
                BottomButtonOnClick={BottomButtonOnClick}
                BottomButtonText={BottomButtonText}
                Disabled={BottomButtonDisabled}
                DisplayNone={DisplayNone}
                Show={Show}
                Size={Size || "large"}
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.props.Loading ? <Loading /> : null}

                {
                    InformationRenderer ?
                    InformationRenderer()
                :
                    <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={Information} TextAlign={TextAlign || 'center'} />
                }
            </AbelModal>
        );
    }
}

Info.propTypes = {
    BottomButton: PropTypes.string,
    BottomButtonDisabled: PropTypes.bool,
    BottomButtonOnClick: PropTypes.func,
    BottomButtonText: PropTypes.string,
    DisplayNone: PropTypes.bool,
    Information: PropTypes.string,
    InformationRenderer: PropTypes.func,
    Loading: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    Size: PropTypes.string,
    TextAlign: PropTypes.string
}

export default Info;