import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CheckLogin, HandleVisibilityChange, ResizeWindow } from './Services/Actions';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import history from './history';

import styled from 'styled-components';

import Header from './Components/Header';
import Loading from './Components/Loading';

import Login from './Pages/Login';
import Payouts from './Pages/Payouts';

import { SetLanguage } from './Functions';

import "./Assets/Translations";
import './index.css';

const BodyContainer = styled.div`
    align-items: ${props => props.IsLoggedIn ? 'unset' : 'center'};
    background-color: ${props => props.IsLoggedIn ? 'var(--gray-light)' : 'var(--blue-astronaut)'};
    display: flex;
    flex-direction: ${props => props.IsLoggedIn ? 'column' : 'row'};
    flex-grow: 1;
    justify-content: ${props => props.IsLoggedIn ? 'unset' : 'center'};
    
    min-height: 100%;
    overflow: ${props => props.IsLoggedIn ? 'scroll' : 'auto'};
`;

class App extends React.Component {
    state = { CurrentPathname: '', Loading: true };

    componentDidMount() {
        this.props.CheckLogin().then(() => {
            this.onSetupLanguage();

            this.setState({ Loading: false });

            // Set Router To Watch Changes
                this.unlisten = history.listen(location => this.onUpdateCurrentLocation(location.pathname));

            // Set Window to Listen For Browser Resize
            // Set Window to Listen For Tab Being Visible / Hidden
                this.props.HandleVisibilityChange();
                this.props.ResizeWindow();
                window.addEventListener('resize', this.props.ResizeWindow);
                document.addEventListener('visibilitychange', this.props.HandleVisibilityChange);
        });
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.props.ResizeWindow);
        document.removeEventListener('visibilitychange', this.props.HandleVisibilityChange);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.Loading) {
            // Logged In User --> Setup Language
                if (prevProps.IsLoggedIn !== null && !!!+prevProps.IsLoggedIn && !!+this.props.IsLoggedIn) {
                    this.onSetupLanguage();
                }
        }
    }

    onSetupLanguage = () => {
        SetLanguage(1);
    }

    onUpdateCurrentLocation = CurrentPathname => {
        this.setState({ CurrentPathname });
    }

    render = () => {
        var { IsLoggedIn } = this.props;

        if (this.state.Loading) return <Loading />;

		return (
            <BodyContainer IsLoggedIn={IsLoggedIn}>
                <Router history={history}>
                    {!!IsLoggedIn && <Header CurrentPathname={this.state.CurrentPathname} />}

                    <Switch>
                        <Route path="/login" exact component={Login} />

                        <Route path="/" exact component={Payouts} />

                        <Redirect to="/" />
                    </Switch>
                </Router>
            </BodyContainer>
		);
    }
}

const mapStateToProps = state => {
    return {
        IsLoggedIn: state.Auth.IsLoggedIn,

        WindowVisible: state.Window.WindowVisible,

        TryingLoginUser: state.Auth.TryingLoginUser,
        TryingLoginUserError: state.Auth.TryingLoginUserError
    };
};

export default withTranslation()(connect(mapStateToProps, { CheckLogin, HandleVisibilityChange, ResizeWindow } )(App));