import styled from 'styled-components';

export const StyledTopBar = styled.div`
    align-items: center;
    background-color: ${props => props.BackgroundColor};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    height: 25px;
    justify-content: space-between;
    padding: 0px 10px;
`;