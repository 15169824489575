import {
    LOGOUT_USER,

    TRYING_LOGIN_USER,
    TRYING_LOGIN_USER_SUCCESS,
    TRYING_LOGIN_USER_ERROR,

    TRYING_VERIFY_USER,
    TRYING_VERIFY_USER_SUCCESS,
    TRYING_VERIFY_USER_ERROR
} from '../Types';

const INITIAL_STATE = {
    IsLoggedIn: 0,

    TryingLoginUser: false,
    TryingLoginUserError: null,
    TryingVerifyUser: false,
    TryingVerifyUserError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGOUT_USER:
            return { ...state, IsLoggedIn: 0, TryingLoginUser: false, TryingLoginUserError: null, TryingVerifyUser: false, TryingVerifyUserError: null };

        case TRYING_LOGIN_USER:
            return { ...state, TryingLoginUser: true, TryingLoginUserError: null };
        case TRYING_LOGIN_USER_ERROR:
            return { ...state, IsLoggedIn: 0, TryingLoginUser: false, TryingLoginUserError: action.payload };
        case TRYING_LOGIN_USER_SUCCESS:
            return { ...state, TryingLoginUser: false, TryingLoginUserError: null };

        case TRYING_VERIFY_USER:
            return { ...state, TryingVerifyUser: true, TryingVerifyUserError: null };
        case TRYING_VERIFY_USER_ERROR:
            return { ...state, IsLoggedIn: 0, TryingVerifyUser: false, TryingVerifyUserError: action.payload };
        case TRYING_VERIFY_USER_SUCCESS:
            return { ...state, IsLoggedIn: 1, TryingVerifyUser: false, TryingVerifyUserError: null };

        default:
            return state;
    }
};
