import i18n from "i18next";
import moment from 'moment';
import 'moment/locale/nb';

var English_LocaleUpdated = false;
var Norwegian_LocaleUpdated = false;

export const emailValidator = Email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return re.test(String(Email).toLowerCase());
}

export function getBorderRadiusFromHeight(Size) {
    switch (Size) {
        case "small":
            return '15px';
        case "medium":
            return '20px';
        case "large":
            return '25px';
        case "extra-large":
            return '33px';
        default:
            return '15px';
    }
}

export const SetLanguage = LanguageId => {
    LanguageId = +LanguageId;

    moment.locale(LanguageId === 1 ? 'en' : 'nb');
    i18n.changeLanguage(LanguageId === 1 ? 'en' : 'no');

    if (LanguageId === 1 && !English_LocaleUpdated) {
        moment.relativeTimeThreshold('ss', 0);

        moment.updateLocale('en', {
            longDateFormat: {
                "[NumbersDate]": "MM-DD-YYYY",
                "[TrackingHistory]": "MM-DD-YYYY hh:mm a",
                "[WeeklyViewDayHeader]" : "MMM D",
                "[WeeklyViewHeader]": "MMM DD, YYYY"
            },
            relativeTime : {
                future: "in %s",
                past:   "%s",
                ss: '%d s',
                m:  "1 m",
                mm: "%d m",
                h:  "1 h",
                hh: "%d h",
                d:  "1 d",
                dd: "%d d",
                w:  "1 w",
                ww: "%d w",
                M:  "1 mn",
                MM: "%d mn",
                y:  "1 y",
                yy: "%d y"
            }
        });

        English_LocaleUpdated = true;
    }
    else if (LanguageId === 2 && !Norwegian_LocaleUpdated) {
        moment.relativeTimeThreshold('ss', 0);

        moment.updateLocale('nb', {
            longDateFormat: {
                "[NumbersDate]": "DD.MM.YYYY",
                "[TrackingHistory]": "DD.MM.YYYY HH:mm",
                "[WeeklyViewDayHeader]" : "DD. MMM",
                "[WeeklyViewHeader]": "DD. MMM YYYY"
            },
            relativeTime : {
                future: "i %s",
                past:   "%s",
                ss: '%d s',
                m:  "1 m",
                mm: "%d m",
                h:  "1 t",
                hh: "%d t",
                d:  "1 d",
                dd: "%d d",
                w:  "1 u",
                ww: "%d u",
                M:  "1 mn",
                MM: "%d mn",
                y:  "1 å",
                yy: "%d å"
            }
        });

        Norwegian_LocaleUpdated = true;
    }
}